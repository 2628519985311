import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BottomBar } from '@techmobilt/ui-components'
import { StyledBottomBar } from './styles'
import { navigate } from 'gatsby'
import { pushDataLayer } from '../../../utils/pushDataLayer'
import { isBrowser } from '../../../utils/generic'
import useSlugs from '../../../hooks/useSlugs'
import { useLocation } from '@reach/router'

const locale = process.env.GATSBY_INTL_LOCALE
const CASINO_CONTEXT = 'casino'
const SPORTS_CONTEXT = 'sports'

const BottomNavigation = ({ eventLocation, navData }) => {
  if (!navData) return <></>
  const { context, nav_items, change_context_icon } = navData

  const location = useLocation()
  const [selectedItem, setSelectedItem] = useState()
  const [sportsSlug, casinoSlug, liveCasinoSlug] = useSlugs([
    'sports',
    'casino',
    'live-casino',
  ])

  useEffect(() => {
    if (!isBrowser()) return
    const selected = findSelectedItem(nav_items, location)
    setSelectedItem(selected)
  }, [location])

  const validateCasinoSlug = ({ pathname, slug, items }) => {
    const IS_CASINO_CONTEXT = context === CASINO_CONTEXT
    const INCLUDES_SLUG = pathname?.includes(`/${slug}/`)
    const IS_PROMO = pathname?.includes('promo')

    if (IS_CASINO_CONTEXT && INCLUDES_SLUG && !IS_PROMO) {
      const selectedItemIndex = items?.findIndex(
        (item) => item.url === `/${locale}/${slug}`
      )

      return selectedItemIndex
    }

    return false
  }

  const findSelectedItem = (items, currentLocation) => {
    const { pathname, search } = currentLocation

    const findCasinoItem = validateCasinoSlug({
      pathname,
      slug: casinoSlug,
      items,
    })

    if (findCasinoItem !== false) return findCasinoItem

    const findLiveCasinoItem = validateCasinoSlug({
      pathname,
      slug: liveCasinoSlug,
      items,
    })

    if (findLiveCasinoItem !== false) return findLiveCasinoItem

    const pathWithSearch = `${pathname}${search || ''}`
    const selectedItemIndex = items?.findIndex((item) => {
      const linkNoSlashes = item?.url?.replaceAll('/', '')?.toLocaleLowerCase()
      const pathNoSlashes = pathWithSearch
        ?.replaceAll('/', '')
        ?.toLocaleLowerCase()
      return pathNoSlashes === linkNoSlashes
    })
    return selectedItemIndex
  }

  const handleNavItemClick = (eventValue) => {
    pushDataLayer({
      eventName: 'bottom_nav_item-opened',
      data: {
        screen_name: eventLocation,
        item_name: eventValue,
      },
    })
  }

  const changeContext = () => {
    const eventValue =
      context === SPORTS_CONTEXT ? 'casino_switch' : 'sports_switch'
    pushDataLayer({
      eventName: 'bottom_nav_item-opened',
      data: {
        screen_name: eventLocation,
        item_name: eventValue,
      },
    })
    if (context === SPORTS_CONTEXT) navigate(`/${casinoSlug}/`)
    if (context === CASINO_CONTEXT) navigate(`/${sportsSlug}/`)
  }

  return (
    <StyledBottomBar>
      <BottomBar
        navItems={nav_items}
        contextChangeIcon={change_context_icon}
        selectedItem={selectedItem}
        changeContext={changeContext}
        handleNavItemClick={handleNavItemClick}
      />
    </StyledBottomBar>
  )
}

BottomNavigation.defaultProps = {
  eventLocation: '',
  navData: null,
}

BottomNavigation.propTypes = {
  eventLocation: PropTypes.string,
  navData: PropTypes.object,
}

export { BottomNavigation }
