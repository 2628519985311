import { graphql, useStaticQuery } from 'gatsby'
import { getWebsiteUrl } from '../../utils/getWebsiteUrl'
import { isFbUser } from '../../helpers/urlHelper'

const websiteURL = getWebsiteUrl()

export const useFooterV2ApiData = () => {
  const data = useStaticQuery(graphql`
    {
      allFooterApiV2 {
        nodes {
          is_fb_data
          footer_links {
            text_links {
              title
              links {
                text
                visibility
                link {
                  url
                  target
                }
                left_side_menu {
                  text
                  link {
                    url
                    target
                  }
                }
              }
            }
            image_sections {
              title
              images {
                image {
                  url
                  alt
                  width
                  height
                }
                link {
                  url
                  target
                }
                visibility
              }
            }
          }
          footer_misc {
            acf_fc_layout
            description
            images {
              link {
                url
                target
              }
              image {
                url
                alt
                width
                height
                sizes {
                  thumbnail
                  thumbnail_width
                  thumbnail_height
                  medium
                  medium_width
                  medium_height
                  medium_large
                  medium_large_width
                  medium_large_height
                  large
                  large_width
                  large_height
                  _1536x1536
                  _1536x1536_width
                  _1536x1536_height
                  _2048x2048
                  _2048x2048_width
                  _2048x2048_height
                }
              }
            }
            items {
              type
              text
              link {
                url
                target
              }
              image {
                url
                alt
                width
                height
              }
              countries {
                code
                name
                flag {
                  url
                  alt
                }
              }
            }
            scripts
            description_html
            logo {
              url
              alt
              width
              height
              sizes {
                thumbnail
                thumbnail_width
                thumbnail_height
                medium
                medium_width
                medium_height
                medium_large
                medium_large_width
                medium_large_height
                large
                large_width
                large_height
                _1536x1536
                _1536x1536_width
                _1536x1536_height
                _2048x2048
                _2048x2048_width
                _2048x2048_height
              }
            }
            link {
              url
              target
            }
          }
        }
      }
    }
  `)

  const defaultFooter = data?.allFooterApiV2?.nodes.find(
    (item) => !item.is_fb_data
  )
  const fbFooter = data?.allFooterApiV2?.nodes.find((item) => item.is_fb_data)

  const footer = isFbUser() ? fbFooter : defaultFooter

  if (!footer) return {}

  const {
    footer_links: { image_sections, text_links },
    footer_misc,
    is_fb_data,
  } = footer

  image_sections?.forEach((section) => {
    section?.images?.forEach((imageLink) => {
      const linkUrl = imageLink?.link?.url
      if (linkUrl?.startsWith('/')) {
        imageLink.link.url = `${websiteURL}${linkUrl.substring(1)}`
      }
    })
  })

  return { text_links, image_sections, footer_misc, is_fb_data }
}
